<template>
  <div class="q-pb-sm">
    <div class="row">
      <div :class="isShowMenu ? 'col-9' : 'col'">
        <slot />
      </div>
      <pro-action-menu-list
        :action-menus="actionMenus"
        :loading="isLoading"
        v-show="isShowMenu"
        class="col-3 q-px-sm"
        style="padding-top: 10px"
      />
    </div>
  </div>
</template>

<script>
import ProActionMenuList from "@/components/PROSmart/ActionMenu/ProActionMenuList";

export default {
  name: "ProMenuLayout",
  components: { ProActionMenuList },
  data() {
    return {
      isShowMenu: true,
      isLoading: true,
      actionMenus: [],
    };
  },
  methods: {
    load() {
      //edit mode not show action menu
      if (this.$route.params.stepperId && this.$route.params.mode !== "Edit") {
        //load action menu
        this.isLoading = true;

        const actionMenuList = JSON.parse(
          sessionStorage.getItem("actionMenuList") || "[]"
        );
        this.actionMenus = actionMenuList.filter(
          (group) =>
            group.menuItems.length &&
            group.menuItems[0].stepperId == this.$route.params.stepperId
        );
        this.isShowMenu = this.actionMenus.length > 0;
        this.isLoading = false;

        // return this.$proSmart.tender
        //   .getActionMenu(
        //     this,
        //     this.$route.params.id,
        //     this.$route.params.stepperId
        //   )
        //   .then((resp) => {
        //     this.actionMenus = resp;
        //     this.isShowMenu = resp.length > 0;
        //   })
        //   .catch((err) => {
        //     this.actionMenus = [];
        //     console.error(err);
        //     this.$alert(
        //       this.getRes("ErrorMessage.LoadActionMenuError"),
        //       this.getRes("WorkflowOperation.Msg_failure1"),
        //       { confirmButtonText: this.getRes("ok") }
        //     );
        //     this.isShowMenu = false;
        //   })
        //   .finally(() => {
        //     this.isLoading = false;
        //   });
      } else {
        this.isShowMenu = false;
      }
    },
  },
  created() {
    this.$watch(
      () => [this.$route.params.stepperId, this.$route.params.mode],
      this.load,
      {
        immediate: true,
      }
    );
  },
};
</script>
