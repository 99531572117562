<template>
  <q-expansion-item v-if="expandable" v-bind="$attrs">
    <template #header>
      <slot name="header" />
    </template>
    <slot />
  </q-expansion-item>
  <q-item v-else v-bind="$attrs">
    <slot name="header" />
    <q-item-section side>
      <div style="width: 24px"></div>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: "ProExpansionItem",
  inheritAttrs: false,
  props: {
    expandable: Boolean,
  },
};
</script>
