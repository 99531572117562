<template>
  <q-card bordered flat>
    <pro-expansion-item
      :expandable="expandable"
      :class="disable ? 'prosmart-disable' : ''"
      :default-opened="!defaultClosed"
    >
      <template #header>
        <q-item-section>
          <div class="row no-wrap items-center q-col-gutter-md">
            <div v-if="alias">
              <q-avatar color="primary" text-color="white" size="lg">
                {{ alias }}
              </q-avatar>
            </div>
            <div :style="$slots.status ? 'flex:1' : ''">
              <div
                class="text-h4 text-primary"
                style="white-space: normal; word-break: break-all"
              >
                {{ $t(title) }}
              </div>
              <div v-if="date" class="text-h6 text-grey-6">
                {{ $proSmart.common.getFormattedDate(new Date(date)) }}
              </div>
            </div>
            <div :style="$slots.status ? '' : 'flex-grow: 1'">
              <slot name="status">
                <q-badge
                  v-if="status"
                  outline
                  align="middle"
                  :color="statusColor"
                >
                  {{ status }}
                </q-badge>
              </slot>
            </div>

            <div>
              <div class="q-gutter-x-sm">
                <slot name="action">
                  <template v-for="action in actions">
                    <q-btn-group
                      outline
                      v-if="action.processId"
                      :key="action.processId"
                      style="min-width:100px"
                    >
                      <q-btn
                        no-caps
                        icon="PROSmart-Docs"
                        :label="$t('System.Button.View')"
                        @click.stop="
                          showRecord(action.processId, action.viewForm)
                        "
                      />
                    </q-btn-group>
                    <pro-button
                      v-else-if="action.icon && !action.label"
                      :key="action.key"
                      :color="action.color || 'primary'"
                      :icon="action.icon"
                      :to="action.to"
                      :disable="disable"
                      v-on="action.onClick ? { click: action.onClick } : {}"
                      unelevated
                    ></pro-button>
                    <pro-button
                      v-else
                      :key="action.key"
                      :color="action.color || 'primary'"
                      :label="action.label"
                      :icon="action.icon"
                      :to="action.to"
                      :disable="disable"
                      v-on="action.onClick ? { click: action.onClick } : {}"
                    ></pro-button>
                    <!--                    <q-btn no-caps size="sm" padding="sm" round unelevated />-->
                    <!--                    <q-btn-->
                    <!--                      no-caps-->
                    <!--                      v-else-->
                    <!--                      :key="action.key"-->
                    <!--                      padding="xs md"-->
                    <!--                      rounded-->
                    <!--                      unelevated-->
                    <!--                    />-->
                  </template>
                </slot>
              </div>
            </div>
          </div>
        </q-item-section>
      </template>

      <q-separator />
      <q-card-section>
        <slot />
      </q-card-section>
    </pro-expansion-item>
    <audit-trail-record-dialog ref="audit"></audit-trail-record-dialog>
  </q-card>
</template>

<script>
import ProExpansionItem from "@/components/PROSmart/QuasarWrapperComponent/ProExpansionItem";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";
import ProButton from "@/components/PROSmart/ProButton";

export default {
  name: "ProDeckCard",
  components: { ProButton, AuditTrailRecordDialog, ProExpansionItem },
  props: {
    alias: String,
    title: {
      type: String,
      required: true,
    },
    date: Date,
    status: String,
    statusColor: {
      type: String,
      default: "primary",
    },
    /** @type {Array<{key: string, label: string, icon: string, color: string, to: Location, onClick: function}>} */
    actions: {
      type: Array,
      default: () => [],
    },
    expandable: Boolean,
    disable: Boolean,
    defaultClosed: Boolean,
  },
  methods: {
    showRecord(processId, viewForm) {
      if (viewForm) {
        this.$refs.audit.showInfoViewForm(
          processId,
          this.$route.params.id,
          true
        );
      } else {
        this.$refs.audit.showInfo(processId, this.$route.params.id, true);
      }
    },
  },
};
</script>
