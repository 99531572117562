import { render, staticRenderFns } from "./ProDeck.vue?vue&type=template&id=d4cfbd34&"
import script from "./ProDeck.vue?vue&type=script&lang=js&"
export * from "./ProDeck.vue?vue&type=script&lang=js&"
import style0 from "./ProDeck.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBadge,QSpace,QChip,QAvatar,QSkeleton,QCard});
