<template>
  <div>
    <q-btn
      v-if="!outline"
      no-wrap
      no-caps
      caps
      flat
      :icon="icon"
      :label="$t(label)"
      :disable="disable"
      :to="to"
      :unelevated="unelevated"
      v-on="$listeners"
      :color="color"
    />

    <q-btn
      v-else
      no-caps
      padding="xs md"
      outline
      color="primary"
      :icon="icon"
      :label="$t(label)"
      :disable="disable"
      :to="to"
      :unelevated="unelevated"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: "ProButton",
  props: {
    label: {
      type: String,
      default: "System.Button.View",
      required: true,
    },
    icon: {
      type: String,
      default: "PROSmart-Docs",
    },
    to: {
      type: Object,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    unelevated: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
