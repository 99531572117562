<template>
  <q-card class="overflow-hidden">
    <q-list>
      <q-item-label
        header
        class="text-h6 text-bold text-black bg-grey-4 q-py-sm"
        >{{ titleText }}</q-item-label
      >
      <q-item
        v-for="item in listItems"
        :key="item.id"
        :clickable="!!(item.action.route || item.action.messages)"
        :to="item.action.route"
        @click="showErrorMessages(item.action.messages)"
      >
        <q-item-section>{{ item.text }}</q-item-section>
      </q-item>
    </q-list>
  </q-card>
</template>

<script>
import ProStepperMixin from "@/views/mixins/ProStepperMixin";

export default {
  name: "ProActionMenu",
  mixins: [ProStepperMixin],
  props: {
    title: String,
    list: Array,
  },
  methods: {
    getAction(node) {
      if (node.viewRoute) {
        return {
          route: {
            name: node.viewRoute,
            params: {
              mode: "View",
              code: node.viewFormCode,
              stepperId: node.stepperId,
            },
          },
        };
      } else if (node.viewMessages) {
        return {
          messages: node.viewMessages,
        };
      } else if (node.editRoute) {
        return {
          route: {
            name: node.editRoute,
            params: {
              mode: "Edit",
              code: node.editWorkflowCode,
              stepperId: node.stepperId,
            },
          },
        };
      } else if (node.editMessages) {
        return {
          messages: node.editMessages,
        };
      } else {
        return {};
      }
    },
  },
  computed: {
    titleText() {
      return this.getRes(this.title);
    },
    listItems() {
      return this.list.map((item) => ({
        id: item.id,
        text: this.getRes(item.descriptionRkey),
        action: this.getAction(item),
      }));
    },
  },
};
</script>
