<template>
  <div>
    <div v-show="loading" class="q-gutter-y-md">
      <pro-menu-skeleton />
      <pro-menu-skeleton />
    </div>
    <div v-show="!loading" class="q-gutter-y-md">
      <pro-action-menu
        v-for="actionMenu in actionMenus"
        :key="actionMenu.id"
        :title="actionMenu.descriptionRkey"
        :list="actionMenu.menuItems"
      ></pro-action-menu>
    </div>
  </div>
</template>

<script>
import ProActionMenu from "@/components/PROSmart/ActionMenu/ProActionMenu";
import ProMenuSkeleton from "@/components/PROSmart/Skeleton/ProMenuSkeleton";

export default {
  name: "ProActionMenuList",
  components: { ProActionMenu, ProMenuSkeleton },
  props: {
    loading: Boolean,
    actionMenus: Array,
  },
};
</script>
