<template>
  <q-dialog v-model="show">
    <q-card style="width: 1800px; max-width: 95%">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h4 q-mt-sm q-mb-xs">
          {{ getRes(title) }}
        </div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup no-caps />
      </q-card-section>

      <q-card-section>
        <audit-trail-record
          :process-id="processId"
          :tender-id="tenderId"
          :show-form="showForm"
          :show-frameset="true"
          :view-form-name="viewFormName"
          :show-form-name="showFormName"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import AuditTrailRecord from "@/components/PROSmart/AuditTrail/AuditTrailRecord";
export default {
  name: "AuditTrailRecordDialog",
  components: { AuditTrailRecord },
  props: {
    title: {
      /**
       * @param {string} i18n key string
       */
      type: String,
      default: "Form.Section.Record",
    },
  },
  data() {
    return {
      processId: "",
      tenderId: "",
      showForm: Boolean,
      show: false,
      viewFormName: false,
      showFormName: "",
    };
  },
  methods: {
    /**
     *
     * @param {string} processId
     * @param {string|number} tenderId
     * @param {boolean} showForm
     */
    showInfo(processId, tenderId, showForm) {
      this.processId = processId;
      this.tenderId = tenderId;
      this.showForm = showForm;
      this.show = true;
    },
    showInfoViewForm(processId, tenderId, showForm) {
      this.showInfo(processId, tenderId, showForm);
      this.viewFormName = true;
    },
    showInfoViewFormByName(processId, tenderId, showForm, formName) {
      this.showInfoViewForm(processId, tenderId, showForm);
      this.showFormName = formName;
    },
  },
};
</script>
