<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('CustMenu.Com.Action.ViewNdaDocument')"
        :cards="ndaSubmissions"
        hide-count-badge
        v-slot="{ card: submission }"
      >
        <pro-deck-card
          :title="$proSmart.common.getFormattedDate(submission.dateReceived)"
          :status="submission.status ? submission.status : null"
          expandable
        >
          <div class="row">
            <div class="col-auto">
              <pro-key-value-table
                :rows="ndaSubmissionTableRows"
                :data="submission"
              />
            </div>
          </div>
          <div class="row items-center">
            <div class="col-auto">{{ getRes("CustMenu.Col.Submission") }}:</div>
            <div class="col-auto">
              <pro-file
                :file="{ fileId: submission.submittedFileId }"
                show-download-button-only
              />
            </div>
          </div>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
import ProFile from "@/components/PROSmart/ProFile.vue";

export default {
  components: {
    ProFile,
    ProMenuLayout,
    ProDeck,
    ProDeckCard,
    ProKeyValueTable,
  },
  data() {
    return {
      ndaFileId: "",
      ndaSubmissions: [],
    };
  },
  computed: {
    cards() {
      return [
        {
          title: "NDA Template",
          fileId: this.ndaFileId,
        },
      ];
    },
    ndaSubmissionTableRows() {
      return [
        {
          label: this.getRes("CustMenu.Col.UploadedBy"),
          field: "createdBy",
        },
      ];
    },
  },
  async created() {
    this.$proSmart.documentUi
      .getNdaFileIdByTenderer(this, this.$route.params.id)
      .then((ndaInfo) => {
        this.ndaFileId = ndaInfo.ndaFileId;
      });
    this.$proSmart.tender
      .getNdaSubmissionByTenderer(this, this.$route.params.id)
      .then((submission) => {
        this.ndaSubmissions = submission.map((nda, index) => ({
          key: index,
          id: nda.id,
          tenderId: nda.tenderId,
          tendererId: nda.tendererId,
          companyName: nda.companyName,
          tendererUserId: nda.tendererUserId,
          dateReceived: nda.dateReceived,
          submitted: nda.submitted,
          submittedFileId: nda.submittedFileId,
          status: nda.status,
          createdBy: nda.createdByUserName,
        }));
      });
  },
};
</script>
