export default {
  methods: {
    showErrorMessages(messages) {
      if (messages) {
        const msgString = messages.reduce(
          (result, msg) => result + "<br>" + this.getRes(msg),
          this.getRes("Form.Message.Error.FormValidationError")
        );
        this.$alert(msgString, "", {
          confirmButtonText: this.getRes("ok"),
          dangerouslyUseHTMLString: true,
        });
      }
    },
    getStepperAction(node) {
      const viewAction = this.getViewAction(node);
      const editAction = this.getEditAction(node);

      if (viewAction && editAction) {
        return this.isCompleted(node) ? viewAction : editAction;
      } else {
        return viewAction || editAction || {};
      }
    },
    getActionMenuAction(node) {
      const viewAction = this.getViewAction(node);
      const editAction = this.getEditAction(node);

      return viewAction || editAction || {};
    },
    getViewAction(node) {
      if (node.viewRoute) {
        return {
          route: {
            name: node.viewRoute,
            params: {
              mode: "View",
              code: node.viewFormCode,
              stepperId: node.stepperId || node.id,
            },
          },
        };
      } else if (node.viewMessages) {
        return {
          messages: node.viewMessages,
        };
      } else {
        return null;
      }
    },
    getEditAction(node) {
      if (node.editRoute) {
        return {
          route: {
            name: node.editRoute,
            params: {
              mode: "Edit",
              code: node.editWorkflowProcessCode || node.editWorkflowCode,
              stepperId: node.stepperId || node.id,
            },
          },
        };
      } else if (node.editMessages) {
        return {
          messages: node.editMessages,
        };
      } else {
        return null;
      }
    },
    isCompleted(node) {
      return (
        node.editWorkflowProcessCode == null || node.flowHistory.length > 0
      );
    },
  },
};
