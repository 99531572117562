<template>
  <div>
    <div class="row no-wrap q-mb-md items-center" v-if="title">
      <div class="col-auto text-h4 text-primary">
        {{ title }}
        <q-badge v-if="!hideCountBadge" color="primary" align="middle">
          {{ cardsFiltered.length }}
        </q-badge>
      </div>
      <div class="col-auto q-ml-xs">
        <slot name="titleAction" />
      </div>
      <q-space />
      <div class="col-auto">
        <slot name="action" />
      </div>
      <div v-if="filterByStatus" class="col-auto">
        <q-chip
          v-for="status in statuses"
          :key="status.key"
          :color="
            statusSelected[status.key] ? status.color || 'primary' : 'grey-6'
          "
          square
          outline
          clickable
          @click="statusSelected[status.key] = !statusSelected[status.key]"
        >
          <q-avatar
            :color="
              statusSelected[status.key] ? status.color || 'primary' : 'grey-6'
            "
            text-color="white"
            >{{ statusCount[status.key] || 0 }}
          </q-avatar>
          {{ getRes(status.label) }}
        </q-chip>
        <q-chip
          v-if="showNoStatus"
          :color="noStatusSelected ? 'primary' : 'grey-6'"
          square
          outline
          clickable
          @click="noStatusSelected = !noStatusSelected"
        >
          <q-avatar
            :color="noStatusSelected ? 'primary' : 'grey-6'"
            text-color="white"
            >{{ noStatusCount }}
          </q-avatar>
          {{ getRes("noStatus") }}
        </q-chip>
      </div>
    </div>
    <div class="row q-gutter-md" v-if="isLoading">
      <q-skeleton
        type="text"
        width="100%"
        height="50px"
        style="transform: scale(1, 1)"
        v-for="item in 5"
        :key="item"
      />
    </div>
    <div class="q-gutter-xs">
      <div v-if="proReportColumns.length === 0">
        <div
          v-for="card in cardsFiltered"
          :key="card.key"
          style="padding-bottom: 16px"
        >
          <slot :card="card" :status="statusMap[card.status]" />
        </div>
      </div>
      <div v-else>
        <pro-deck-card
          expandable
          :title="proReportTitle"
          class="pro-deck-pro-report"
        >
          <pro-report
            :row-key="proReportKey"
            :value="cardsFiltered"
            :columns="proReportColumns"
            simple-table-mode
          ></pro-report>
        </pro-deck-card>
      </div>
    </div>
    <div v-show="cardsFiltered.length === 0" class="text-center q-pa-md">
      <!--      {{ noResultsLabel || getRes("Form.Message.Error.NoRecordFound") }}-->
    </div>
  </div>
</template>

<script>
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard.vue";
import ProReport from "@/components/PROSmart/Report/ProReport.vue";

export default {
  name: "ProDeck",
  components: { ProReport, ProDeckCard },
  props: {
    title: {
      type: String,
    },
    /** @type {Array<{key: string, status: string}>} */
    cards: {
      type: Array,
      default: () => [],
    },
    /** @type {Array<{key: string, label: string, color: string}>} */
    statuses: {
      type: Array,
      default: () => [],
    },
    filterByStatus: Boolean,
    showNoStatus: Boolean,
    hideCountBadge: Boolean,
    noResultsLabel: String,
    proReportTitle: String,
    proReportColumns: {
      type: Array,
      default: () => [],
    },
    proReportKey: {
      type: String,
      default: "id",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusSelected: {},
      noStatusSelected: true,
    };
  },
  computed: {
    cardsFiltered() {
      if (this.filterByStatus) {
        return this.cards.filter((card) => {
          if (card.status) {
            return this.statusSelected[card.status];
          } else {
            return this.showNoStatus && this.noStatusSelected;
          }
        });
      } else {
        return this.cards;
      }
    },
    statusMap() {
      return this.statuses.reduce((map, elem) => {
        map[elem.key] = elem;
        return map;
      }, {});
    },
    statusCount() {
      return this.cards.reduce((countMap, elem) => {
        countMap[elem.status] = (countMap[elem.status] || 0) + 1;
        return countMap;
      }, {});
    },
    noStatusCount() {
      return this.cards.reduce((count, elem) => {
        if (!elem.status) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);
    },
  },
  watch: {
    statuses: {
      handler: function () {
        this.statusSelected = this.statuses.reduce((map, elem) => {
          map[elem.key] = true;
          return map;
        }, {});
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.pro-deck-pro-report .q-card__section {
  padding: 0;
}
</style>
