<template>
  <q-card>
    <q-list>
      <q-item-label header class="bg-grey-4 q-py-md"></q-item-label>
      <q-item>
        <q-skeleton type="text" width="50%" />
      </q-item>
      <q-item>
        <q-skeleton type="text" width="90%" />
      </q-item>
    </q-list>
  </q-card>
</template>

<script>
export default {
  name: "ProMenuSkeleton",
};
</script>
